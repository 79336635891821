<template>
    <div class="main">
        <div class="container">
            <div class="suggest-search" v-if="!isWithProduct">
                <div class="loading" v-if="!isSuggestTagReady">
                    <loader-content :isDisplay="true"></loader-content>
                </div>
                <suggest-tags :tags="suggestTags" v-if="isSuggestTagReady"></suggest-tags>
            </div>
        </div>

        <div class="cover">
            <img :src="topBanner.img" :alt="topBanner.title" :title="topBanner.title" v-if="topBanner" />
            <img src="@/assets/default-cover.jpg" :alt="store.name" :title="store.name" v-else />
        </div>

        <div class="container">
            <div class="profile">
                <div class="pictures">
                    <swiper :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in store.pictures" :key="index">
                            <div class="item">
                                <img :src="item"
                                    :alt="store.imageAlt[index]"
                                    :title="store.imageAlt[index]"
                                    @click="openFullPicture(index)"
                                    @error="helper.setNoImg($event, 'store')" />
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>

                <div class="info">
                    <h1>{{ store.name }}</h1>

                    <!-- <div class="rating">
                        <b-icon icon="star-fill"
                            class="icon"
                            :class="{ 'active': (index + 1) <= store.starRating }"
                            v-for="(star, index) in 5"
                            :key="index">
                        </b-icon>
                        <div class="users" v-if="store.totalUserRating">({{ store.totalUserRating }})</div>
                    </div> -->

                    <div class="about-store" :class="{ 'mobile': (isWithProduct && !isToggleMobileInfo) }">
                        <button class="btn-expand" @click="isToggleMobileInfo = true" v-if="isWithProduct && !isToggleMobileInfo">
                            <b-icon icon="chevron-down"></b-icon>
                        </button>

                        <div class="wrapper">
                            <address>
                                <section v-if="store.description">{{ store.description }}</section>
                                <a :href="'tel:' + storeTel" class="tel" v-if="storeTel">
                                    <b-icon icon="telephone"></b-icon> ติดต่อร้านค้า
                                </a>
                            </address>
                            <section class="more-info">
                                <div class="item">
                                    <label>จุดสังเกต</label>
                                    <span>{{ store.observationPoint }}</span>
                                </div>
                                <div class="item" v-if="store.openTimeDetail.length > 0">
                                    <label>เวลาทำการ</label> 
                                    <div class="data-list">
                                        <div class="data" v-for="(item, index) in store.openTimeDetail" :key="index">
                                            <span class="day">เปิด {{ item.day }}</span>
                                            <span>เวลา {{ item.time }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="item service-info" v-if="store.serviceInfo">
                                    <label>บริการ</label>
                                    <div class="checkboxs">
                                        <div class="item" v-for="(item, index) in checkListServiceInfo" :key="index">
                                            <div class="box-square">
                                                <b-icon icon="check2" v-if="store.serviceInfo[item.property]"></b-icon>
                                            </div>
                                            <span>{{ item.text }}</span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div class="map" v-if="!isWithProduct">
                <iframe :src="store.googleMap" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>

        <modal-full-picture
            :pictureList="store.pictures"
            :isDisplay="isShowFullPicture"
            :pictureIndex="selectedPictureIndex"
            :pictureName="store.name"
            @close="closeFullPicture()">
        </modal-full-picture>
    </div>
</template>

<script>
import MixinDragScroll from '@/mixins/dragScroll';
import Helper from '@/services/helper';
import MasterService from '@/services/masterService';
import SuggestTags from '@/components/gadget/SuggestTags';

export default {
    mixins: [ MixinDragScroll ],
    components: {
		SuggestTags
	},
    data() {
        return {
            helper: Helper,
            isSuggestTagReady: false,
            suggestTags: null,
            isShowFullPicture: false,
            selectedPictureIndex: 0,
            swiperOptions: {
                loop: true,
                autoplay: {
                    delay: 5000
                },
                pagination: {
                    el: '.swiper-pagination'
                }
            },
            storeTel: '',
            isToggleMobileInfo: false,
            checkListServiceInfo: Helper.getServiceInfoCheckbox(),
        };
    },
	props: {
        store: {
            type: Object,
            required: true
        },
        topBanner: {
            type: Object,
            required: false,
            default: null
        },
        isWithProduct: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mounted() {
        this.getSuggestTags();
        this.getStoreTel();
    },
    methods: {
        async getSuggestTags() {
			this.isSuggestTagReady = false;

            const result = await MasterService.getSuggestTags();

			if (result.data) {
				this.suggestTags = result.data
				this.isSuggestTagReady = true;
			}
		},
        getStoreTel() {
            const contactData = this.store.tel.split(',');

            if (contactData.length > 0) {
                this.storeTel = contactData[0];
            } 
        },
        getTitleName() {
            return [ this.product.brand, this.product.name, this.product.size ].join(' ');
        },
        openFullPicture(index) {
            this.selectedPictureIndex = index;
            this.isShowFullPicture = true;
        },
        closeFullPicture() {
            this.isShowFullPicture = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.cover {
    max-width: 1200px;
    margin: auto;

    img {
        width: 100%;
    }
}

.main {
    position: relative;
    background: #fff;

    .suggest-search {
		padding: 15px 0;

		.loading {
			height: 70px;
		}
	}

    .profile {
        display: flex;
        align-items: flex-start;
        margin-top: -50px;
        margin-left: 20px;

        @media only screen and (max-width: $screenSmall) {
            display: block;
            margin-left: 0;
        }
    }

    .pictures {
        position: relative;
        border: 5px solid #fff;
        width: 450px;
        overflow: hidden;
        @include borderRadius(7px);
        @include boxShadow(0 0 10px rgba(0, 0, 0, 0.2));

        @media only screen and (max-width: $screenLarge) {
            width: 380px;
        }

        @media only screen and (max-width: $screenMedium) {
            width: 320px;
        }

        @media only screen and (max-width: $screenSmall) {
            width: 100%;
            max-width: 450px;
            margin: auto;
        }

        .item {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }
    }

    .info {
        flex: 1;
        margin-top: 80px;
        padding: 0 30px 20px;

        @media only screen and (max-width: $screenSmall) {
            padding: 20px 0;
            margin-top: 0;;
            text-align: center;
        }

        h1 {
            font-family: 'Open Sans', 'Kanit';
            font-weight: bold;
            font-size: 22px;
            margin: 0;
        }

        .about-store {
            position: relative;
            background: #fff;

            @media only screen and (max-width: $screenSmall) {
                &.mobile {
                    .wrapper {
                        -webkit-mask-image: linear-gradient(to bottom, black 30%, transparent 100%);
                        mask-image: linear-gradient(to bottom, black 30%, transparent 100%);
                        height: 100px;
                        overflow: hidden;
                    }
                }
            }

            .btn-expand {
                position: absolute;
                left: 50%;
                z-index: 1;
                margin-left: -17px;
                display: none;
                bottom: 0px;
                background: rgba(50, 50, 50, 0.75);
                border: none;
                color: #fff;
                font-size: 25px;
                padding: 0 5px;
                cursor: pointer;
                @include borderRadius(5px);
                @include boxShadow(0 0 10px rgba(100, 100, 100, 0.5));

                @media only screen and (max-width: $screenSmall) {
                    display: block;
                }
            }

            address {
                width: 100%;
                font-size: 13px;
                color: #666;
                margin: 0;
                margin-top: 5px;

                .tel {
                    margin-top: 13px;
                    display: inline-block;
                    background: #fff400;
                    color: #333;
                    font-size: 13px;
                    padding: 6px 13px;
                    @include borderRadius(5px);

                    .bi {
                        margin-right: 5px;
                        font-size: 11px;
                        opacity: 0.7;
                    }
                }
            }

            .more-info {
                margin-top: 30px;
                padding: 20px 0 0;
                border-top: 1px dashed #ccc;
                text-align: left;

                .item {
                    display: flex;
                    margin-bottom: 3px;
                    font-size: 13px;

                    &.service-info {
                        @media only screen and (max-width: $screenExtraSmall) {
                            display: block;
                        }
                    }

                    label {
                        margin: 0;
                        font-size: 13px;
                        font-weight: bold;
                        width: 100px;
                        
                        @media only screen and (max-width: $screenSmall) {
                            width: 80px;
                        }
                    }
                    
                    span {
                        color: #666;
                        flex: 1;
                    }

                    .data-list {
                        .data {
                            display: flex;

                            .day {
                                width: 140px;

                                @media only screen and (max-width: $screenSmall) {
                                    width: 120px;
                                }
                            }
                        }
                    }

                    .checkboxs {
                        display: flex;
                        flex-wrap: wrap;
                        flex: 1;

                        @media only screen and (max-width: $screenExtraSmall) {
                            margin-top: 7px;
                        }

                        .item {
                            display: flex;
                            width: 250px;
                            margin-bottom: 5px;

                            @media only screen and (max-width: $screenExtraSmall) {
                                width: 50%;
                                padding-right: 10px;
                            }

                            .box-square {
                                width: 20px;
                                height: 20px;
                                background: #fff;
                                margin-top: 2px;
                                border: 1px solid #333;
                                color: #333;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 14px;
                            }

                            span {
                                margin-left: 10px;
                                margin-top: 2px;
                                font-size: 13px;
                                flex: 1;
                            }
                        }
                    }
                }

                article {
                    font-size: 13px;
                    line-height: 18px;

                    p {
                        margin: 0;
                    }
                }
            }
        }
    }

    .map {
        width: 100%;
        height: 180px;
        padding: 0 25px 30px;

        @media only screen and (max-width: $screenSmall) {
            padding: 0;
            padding-bottom: 10px;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
</style>